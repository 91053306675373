import { NativeBaseProvider, Popover, Icon, IconButton, Checkbox, VStack, Text, View, Heading, Box } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import get from 'lodash/get';
import theme from '../nativebase/theme';
import { overlays } from '../fixtures/mapStyles';
import { useIsPhoneSized } from '../utils';

function LayerControl({ explore, setExplore, legend, isOpen, onOpen, onClose }) {
  const layers = get(explore, 'layers', []);
  const isPhoneSized = useIsPhoneSized();

  useEffect(() => {
    // TODO: Update this when we have layers in the API response
    // Update layers in explore state when legend changes
    if (legend) {
      const legendLayers = legend.map((item) => item.name.toLowerCase());
      const hasLevees = legendLayers.includes('levees');
      const leveesIsActive = layers.includes('levees');
      const newLayers = !hasLevees ? layers.filter((layer) => layer !== 'levees') : !leveesIsActive ? [...layers, 'levees'] : layers;
      setExplore({ ...explore, layers: newLayers });
    }
  }, [legend]);

  const handleLayerToggle = (layer, layers) => {
    const layerName = layer.toLowerCase();
    let newLayers = [];
    if (layers.includes(layerName)) {
      newLayers = layers.filter((l) => l !== layerName);
    } else {
      newLayers = [...layers, layerName];
    }
    const newExplore = {
      ...explore,
      layers: newLayers
    };
    setExplore(newExplore);
  };
  return (
    <NativeBaseProvider theme={theme}>
      <Popover
        trigger={(triggerProps) => {
          return (
            <View>
              <IconButton variant='googlemaps' icon={<Icon as={MaterialCommunityIcons} name='layers' size='md' />} {...triggerProps} style={{ marginRight: 10, marginBottom: 10 }} />
            </View>
          );
        }}
        placement="left top"
        offset={10}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <Popover.Content accessibilityLabel="Select layers" style={styles.container} mt={isPhoneSized ? '-9' : 'auto'}>
          <Heading size='sm' variant='menuhead' color='primary.700'><Icon color='primary.700' as={MaterialCommunityIcons} name='layers' size={18} /> Layers</Heading>
          <VStack>
            {overlays.map((overlay) => (
              <Box key={overlay.slug} _dark={{bg: 'primary.100', borderBottomColor: 'primary.300', borderBottomWidth: 1, margin: 0, paddingLeft: 3, paddingTop: 1.5, paddingBottom: 1.5 }} bg='primary.500'>
                <Checkbox
                  isChecked={layers.includes(overlay.slug)}
                  onChange={() => handleLayerToggle(overlay.slug, layers)}
                  colorScheme={'primary'}
                  _checked={{ bg: 'tertiary.600', borderColor: 'tertiary.600' }}
                  _hover={{ bg: 'tertiary.600', borderColor: 'tertiary.600' }}
                  _icon={{ color: 'white' }}
                  _dark={{ bg: 'primary.100', borderWidth: 1, borderRadius: 0 }}
                >
                  <Text variant='layer-label' mx={2} color={'black'}>{overlay.name}</Text>
                </Checkbox>
              </Box>
            ))}
          </VStack>
        </Popover.Content>
      </Popover>
    </NativeBaseProvider>
  );
}

export default LayerControl;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(0, 0, 0)',
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    borderRadius: 2,
    borderWidth: 0,
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    width: 150,
    marginBottom: 0,
  }
});
