import { useRef, useEffect } from 'react';
import { useBreakpointValue } from 'native-base';
import { Text, View } from 'native-base';
import { QueryClient } from 'react-query';
import get from 'lodash/get';
import config from './app.config';
const { MAX_ZOOM, MIN_ZOOM } = config.expo.extra;

function getCoordinatesDump(geojson) {
  switch (geojson.type) {
    case 'Point':
      return [geojson.coordinates];
    case 'LineString':
    case 'MultiPoint':
      return geojson.coordinates;
    case 'Polygon':
    case 'MultiLineString':
      return geojson.coordinates.reduce(function(dump, part) {
        return dump.concat(part);
      }, []);
    case 'MultiPolygon':
      return geojson.coordinates.reduce(function(dump, poly) {
        return dump.concat(poly.reduce(function(points, part) {
          return points.concat(part);
        },[]));
      },[]);
    case 'Feature':
      return getCoordinatesDump(geojson.geometry);
    case 'GeometryCollection':
      return geojson.geometries.reduce(function(dump, geometry) {
        return dump.concat(getCoordinatesDump(geometry));
      },[]);
    case 'FeatureCollection':
      return geojson.features.reduce(function(dump, feature) {
        return dump.concat(getCoordinatesDump(feature));
      },[]);
  }
}

function getBoundingBox(geojson) {
  let coords, bbox;
  if (!geojson.hasOwnProperty('type')) return;
  coords = getCoordinatesDump(geojson);
  bbox = [
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
  ];
  return coords.reduce(function(prev, coord) {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.max(coord[0], prev[2]),
      Math.max(coord[1], prev[3])
    ];
  }, bbox);
};

function getBounds(geojson) {
  const bbox = getBoundingBox(geojson);
  return { west: bbox[0], south: bbox[1], east: bbox[2], north: bbox[3] }; // google.maps.LatLngBoundsLiteral
}

export function getBoundsRestriction(scope) {
  if (!scope) return null;
  const { north, south, east, west } = getBounds(scope);
  const buffer = 1;
  const bufferedBounds = {
    north: north + buffer,
    south: south - buffer,
    east: east + buffer,
    west: west - buffer,
  };
  return {
    latLngBounds: bufferedBounds,
    strictBounds: false,
  }
}

function containedBy(longitude, latitude, bounds) {
  return longitude > bounds.xmin && longitude < bounds.xmax && latitude > bounds.ymin && latitude < bounds.ymax;
}

export function isWithinPolygon(longitude, latitude, geojson) {
  const point = [+longitude, +latitude];
  return booleanPointInPolygon(point, geojson);
}

export function isWithinBoundingBox(longitude, latitude, boundsList) {
  let isWithin = false;
  for (let i = 0; i < boundsList.length; i += 1) {
    if (containedBy(longitude, latitude, boundsList[i])) {
      isWithin = true;
      break;
    }
  }
  return isWithin;
}

export function isWithinContiguousUSA(longitude, latitude) {
  const bounds = {
    xmin: -124.85,
    xmax: -66.89,
    ymin: 24.5,
    ymax: 49.39,
  };
  return isWithinBoundingBox(longitude, latitude, [bounds]);
}

export function isWithinUSA(longitude, latitude) {
  const mainland = {
    xmin: -169.5, // mainland Alaska
    xmax: -66.89, // Maine
    ymin: 24.5, // Florida
    ymax: 71.5, // Alaska
  };

  // St. Lawrence Island, Alaska
  const stLawrence = {
    xmin: -172.5,
    xmax: -169.5,
    ymin: 61,
    ymax: 64,
  };

  const pacificEastLng = {
    xmin: 172, // Aleutian Islands
    xmax: 180, // meridian
    ymin: 18, // Hawaii
    ymax: 61, // Bering Sea
  };
  const pacificWestLng = {
    xmin: -180, // meridian
    xmax: -124, // California
    ymin: 18, // Hawaii
    ymax: 61, // Bering Sea
  };

  const boundsList = [mainland, stLawrence, pacificWestLng, pacificEastLng];
  return isWithinBoundingBox(longitude, latitude, boundsList);
}

export function validateSettings(serverSettings, localSettings, addBanner) {
  // If localSettings is not passed, default to the current_value each setting
  const apiSettings = [...(serverSettings.main_settings || []), ...(serverSettings.other_settings || []), ...(serverSettings.locked_settings || [])];
  const validSettings = {};
  apiSettings.forEach(setting => {
    const validLocalSetting = localSettings && setting.choices.find(choice => choice.value === localSettings[setting.name]);
    if (validLocalSetting && validLocalSetting.value) {
      validSettings[setting.name] = validLocalSetting.value;
    } else {
      if (addBanner) addBanner({
        type: 'info',
        text: `The value of '${setting.label}' was automatically updated. Please verify that it is correct.`,
        location: 'scenarios-popover',
      }, 10000); // 10 seconds
      validSettings[setting.name] = setting.current_value;
    }
  });
  return validSettings;
}

export function getURLParamsFromNestedSettings(settings) {
  const params = {};
  for (let key in settings) {
    if (settings.hasOwnProperty(key)) {
      if (Array.isArray(settings[key])) {
        params[key] = settings[key].join(',');
      } else if (typeof settings[key] === 'object') {
        Object.assign(params, getURLParamsFromNestedSettings(settings[key]));
      } else {
        params[key] = String(settings[key]);
      }
    }
  }
  return params;
}

// A React hook to get the previous value of a prop or state
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// Defining this here because it's a common breakpoint that we're using in multiple places
export function useIsTabLayout() {
  return useBreakpointValue({
    base: true,
    xl: false,
  });
}

export function useIsPhoneSized() {
  return useBreakpointValue({
    base: true,
    md: false,
  });
}

export function getDeltasFromZoom(zoom) {
  return zoom && {
    latitudeDelta: 360 / Math.pow(2, parseFloat(zoom)),
    longitudeDelta: 180 / Math.pow(2, parseFloat(zoom)),
  };
}

// TODO: Use this function to get zoom level for sharing links from mobile
// It may be needed in other cases too
export function getZoomFromDeltas(latitudeDelta) {
  return Math.log2(360 / latitudeDelta);
}

export function getLeveeOpacity(zoom) {
  // adjust opacity from 0 at MIN_ZOOM to 1 at MAX_ZOOM)
  return Math.max(0, Math.min(1, (zoom - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)));
}

const FEATURE_OPACITY = 0.75;

export function isDataFeature(event) {
  const { feature } = event;
  const featureType = feature.getProperty('type');
  return featureType === 'data';
}

export function isRegionOption(event) {
  const { feature } = event;
  const featureType = feature.getProperty('type');
  const isSelected = feature.getProperty('selected');
  return featureType === 'region' && !isSelected;
}

function getMarkerSymbol(fillColor, strokeColor, strokeWeight, scale) {
  return {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor,
    fillOpacity: 1.0,
    strokeColor: strokeColor || '#FFFFFF',
    strokeWeight: strokeWeight || 1.0,
    scale: scale || 10,
  };
}

export function getFeatureStyle(feature, showRegions, showBounds, categories) {
  const isSelected = feature.getProperty('selected');
  if (isSelected) {
    return {
      clickable: false,
      fillOpacity: showBounds ? 0.05 : 0,
      strokeWeight: showBounds ? 0.5 : 0,
    };
  }
  const isData = feature.getProperty('type') === 'data';
  const category = feature.getProperty('category');
  const fillColor = feature.getProperty('color') || feature.getProperty('fillColor');
  const strokeColor = feature.getProperty('strokeColor');
  const strokeWeight = feature.getProperty('strokeWeight');
  const scale = feature.getProperty('scale');
  const icon = getMarkerSymbol(fillColor, strokeColor, strokeWeight, scale);
  const regionWeight = showRegions ? 0.5 : 0;
  const visible = isData ? category && categories && categories.includes(category) : true;
  return {
    fillColor,
    fillOpacity: isData ? FEATURE_OPACITY : 0,
    strokeColor: '#333',
    strokeOpacity: 1.0,
    strokeWeight: isData ? 0.75 : regionWeight,
    visible,
    icon,
  };
}

export function getFeaturePosition(feature) {
  const latitude = feature.getProperty('latitude');
  const longitude = feature.getProperty('longitude');
  return new window.google.maps.LatLng(latitude, longitude);
}

export function getIcon(place) {
  return {
    url: place.icon,
    size: new window.google.maps.Size(71, 71),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(17, 34),
    scaledSize: new window.google.maps.Size(25, 25),
  };
}

export function setMarker(map, place) {
  const icon = getIcon(place);
  return new window.google.maps.Marker({
    map,
    icon,
    title: place.name,
    position: place.geometry.location,
  });
}

const getDataURL = async (url) => {
  const res = await fetch(url)
    .then((r) => {
      if (r.ok) return r;
      console.error('Error fetching dataURL', r);
    })
    .catch((error) => {
      console.error(error);
    });
  if (!res) return null;
  const blob = await res.blob();
  return URL.createObjectURL(blob);
};

export const download = async (url, name = 'image.png') => {
  const a = document.createElement('a');
  const dataURL = await getDataURL(url).then((res) => {
    if (res && res.length > 10) return res;
    return null;
  });
  if (!dataURL) {
    throw new Error('Failed to get a dataURL to download');
  }
  a.href = dataURL;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return dataURL;
};

export function renderTemplate(card, isLong, asPercent) {
  const isPhoneSized = useIsPhoneSized();
  const cardFontSize = isPhoneSized ? '2xl' : '5xl';
  const cardType = get(card, 'type');
  const isRiskEstimate = cardType === 'risk_estimate';
  const isSolution = cardType === 'solution';
  const headlineType = isSolution ? 'headline' : 'headline.text';
  const lengthType = isLong ? 'long' : 'short';
  const riskType = isRiskEstimate ? (asPercent ? 'as_percent' : 'as_value') : '';
  const headlinePath = `${headlineType}.${lengthType}${riskType ? '.' + riskType : ''}`;
  const str = get(card, headlinePath) || '';
  const regex = /\$\{([^}]+)\}/g;
  let result = [];
  let lastIndex = 0;
  str.replace(regex, (match, path, index) => {
  
    const value = get(card, 'headline.' + path) || `[${path}]`;
    result.push(isLong
      ? <Text key={index + 'start'} variant='card' fontSize={cardFontSize}>{str.substring(lastIndex, index)}</Text>
      : <Text key={index + 'start'}>{str.substring(lastIndex, index)}</Text>);

    const placeholderType = path.split('.')[0];
    result.push(isLong
      ? <Text variant={placeholderType === 'estimate' ? 'card-bold' : 'card-accent'} fontSize={cardFontSize} key={index} style={{ fontWeight: 'bold' }} color={placeholderType === 'estimate' ? 'amber.400' : 'white'}>{value}</Text>
      : <Text variant='card-bold' key={index + 'headlinetext'} style={{ fontWeight: 'bold' }} color={placeholderType === 'estimate' ? 'amber.400' : 'white'}>{value}</Text>);

    lastIndex = index + match.length;
  });

  result.push(isLong
    ? <Text key='last' variant='card' fontSize={cardFontSize}>{str.substring(lastIndex)}</Text>
    : <Text key='last'>{str.substring(lastIndex)}</Text>);

  return isLong
    ? <View style={{ flexDirection: 'row' }}><Text variant='card' fontSize={cardFontSize}>{result}</Text></View>
    : <View style={{ flexDirection: 'row' }}><Text variant='card'>{result}</Text></View>
}

export function generateScaleLabels(minValue, maxValue) {
    // Helper function to find the nearest "neat" step value
    function getNeatStepSize(range, count) {
        const rawStep = range / (count - 1);
        const powerOfTen = Math.pow(10, Math.floor(Math.log10(rawStep)));
        const fraction = rawStep / powerOfTen;
        let neatStep;
        
        if (fraction < 1.5) {
            neatStep = 1;
        } else if (fraction < 3.5) {
            neatStep = 2;
        } else if (fraction < 7.5) {
            neatStep = 5;
        } else {
            neatStep = 10;
        }
        
        return neatStep * powerOfTen;
    }

    // Determine the best number of labels (between 4 and 6)
    const options = [4, 5, 6];
    let bestLabels = [];
    let smallestError = Number.MAX_VALUE;

    options.forEach(numLabels => {
        const stepSize = getNeatStepSize(maxValue - minValue, numLabels);
        const endValue = minValue + stepSize * (numLabels - 1);
        const error = Math.abs(endValue - maxValue);

        if (error < smallestError || (error === smallestError && numLabels > bestLabels.length)) {
            smallestError = error;
            const labels = [];
            for (let i = 0; i < numLabels; i++) {
                labels.push(Math.round(minValue + i * stepSize));
            }
            bestLabels = labels;
        }
    });

    return bestLabels;
}

export const scrollToRef = (targetRef) => {
  if (!targetRef || !targetRef.current) return console.log('No targetRef');
  targetRef.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

export const queryClient = new QueryClient();
